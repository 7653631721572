import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import ladderImage from "../../../images/ladder.png";
import { ratio } from "../../../styles/mixins";
import { design } from "../../../styles/settings";
import { useViewportScroll, motion, useTransform } from "framer-motion";
import { easing } from "../../../util/math";

const Wrapper = styled(motion.div)`
	position: absolute;
	will-change: transform;
	${(props) =>
		css`
            ${props.top && `top: ${props.top};`}
            ${props.left && `left: ${props.left};`}
            ${props.right && `right: ${props.right};`}
            ${props.bottom && `bottom: ${props.bottom};`}
            ${props.opacity && `opacity: ${props.opacity};`}
            ${props.blur && `filter: blur(${props.blur});`}
            ${
							props.widths &&
							ratio(
								"width",
								0,
								{
									mn: { base: props.widths[0], ratio: 1 },
									md: { base: props.widths[1], ratio: 1 },
									ms: { base: props.widths[2], ratio: 1 },
								},
								design
							)
						}}
        `}
`;

export default function Ladder(props) {
	const { inputYRange, outputYRange, rotate } = props;

	const { scrollY } = useViewportScroll();

	const ladderProgressY = useTransform(scrollY, inputYRange, outputYRange, {
		ease: easing.outQuad,
	});

	return (
		<Wrapper
			{...props}
			style={{
				y: inputYRange && outputYRange ? ladderProgressY : null,
				rotate: rotate || null,
			}}>
			<img src={ladderImage} alt="" />
		</Wrapper>
	);
}
