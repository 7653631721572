import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { fontSize, grid, spacing, screen } from "../../../styles/mixins";
import {
	fontRoslindaleDisplay,
	fontSurt,
	h2,
	meta,
} from "../../../styles/typography";
import Button from "../../UI/Button";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";

const Container = styled.div`
	${grid};
	${spacing("paddingTop", "l")};
	${spacing("paddingBottom", "l")};
	${spacing("marginBottom", "xl")};
`;
const FeatureBackground = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	transition: transform 300ms ease-in-out;
	width: 100%;
	height: 80%;
	transform: none;

	@media ${screen("md")} {
		height: 100%;
	}
`;
const FeatureImage = css`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`;
const FeatureContainer = styled.div`
	width: 100%;
	grid-column: 5 / -5;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 0;
	${spacing("paddingTop", "xl")};
	${spacing("paddingBottom", "xl")};
	${spacing("marginTop", "l")};
	${spacing("marginBottom", "xl")};

	&:hover {
		${FeatureBackground} {
			transform: scale(1.03);
		}
	}
`;
const FeatureTitle = styled.h2`
	text-align: center;
	${h2}
	line-height: 0.9;
	${spacing("marginBottom", "l")};

	strong {
		${fontSurt};
		font-weight: normal;
	}
`;
const FeatureSubtitle = styled.span`
	${meta};
	font-weight: bold;
	${spacing("marginBottom", "l")};
`;
const FeatureButtonWrapper = styled.div`
	position: relative;
	z-index: 1;
`;
const FeatureLink = styled(Link)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`;

const AdditionalChapters = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 5 / -5;
	justify-content: center;
	align-items: center;

	@media ${screen("md")} {
		flex-direction: row;
	}
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
	z-index: 0;
	${(props) => props.active === false && "opacity: 0.3;"}
	${spacing("marginBottom", "l")};

	@media ${screen("md")} {
		width: 30%;
		margin-bottom: 0;
	}
`;
const CardSubtitle = styled.span`
	${meta};
	${spacing("marginBottom", "s")};
	font-weight: bold;

	@media ${screen("md")} {
		${spacing("marginBottom", "m")};
	}
`;
const CardTitle = styled.h2`
	text-align: center;
	${fontSize(2.5)};
	${fontRoslindaleDisplay};
	line-height: 1;
	${spacing("marginBottom", "s")};

	@media ${screen("md")} {
		${spacing("marginBottom", "m")};
	}

	&:last-child {
		margin-bottom: 0;
	}

	strong {
		${fontSurt};
		font-weight: normal;
	}
`;
const CardLink = styled(Link)``;
const CardComingSoon = styled.div`
	${meta};
	font-weight: bold;
`;
export default function FooterNavigation({ acts }) {
	let feature = acts[0];

	return (
		<Container>
			<FeatureContainer>
				<FeatureBackground>
					<StaticImage
						css={FeatureImage}
						src="../../../images/card-collection.png"
						alt={feature.title}
						layout="fullWidth"
						sizes="100vw"
						objectFit="contain"
						placeholder="none"
					/>
				</FeatureBackground>
				<FeatureSubtitle>{feature.subtitle.toUpperCase()}</FeatureSubtitle>
				<FeatureTitle dangerouslySetInnerHTML={{ __html: feature.title }} />
				<FeatureButtonWrapper>
					<Button to={feature.link} isFilled={true} theme="light">
						{feature.linkTitle}
					</Button>
				</FeatureButtonWrapper>
				<FeatureLink to={feature.link} />
			</FeatureContainer>
			<AdditionalChapters>
				{acts
					.slice(1)
					.map(({ title, subtitle, link, active, linkTitle }, i) => (
						<Card key={i} active={active}>
							{subtitle ? <CardSubtitle>{subtitle}</CardSubtitle> : null}
							<CardTitle dangerouslySetInnerHTML={{ __html: title }} />
							{link ? (
								<CardLink to={link}>{linkTitle.toUpperCase()}</CardLink>
							) : linkTitle ? (
								<CardComingSoon>{linkTitle.toUpperCase()}</CardComingSoon>
							) : (
								""
							)}
						</Card>
					))}
			</AdditionalChapters>
		</Container>
	);
}
