import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import importDirectory from "../util/importDirectory";
import { useUIState } from "../contexts/UIStore";

let features = importDirectory(
	require.context("../components/", true, /Feature\/Home\/[a-zA-Z_-]*\.js$/),
	"default"
);
let structure = importDirectory(
	require.context("../components/", true, /Structure\/[a-zA-Z_-]*\.js$/),
	"default"
);
let sectionLayouts = importDirectory(
	require.context("../components/", true, /SectionLayout\/[a-zA-Z_-]*\.js$/),
	"default"
);

let components = { ...structure, ...features };
let animations = {
	enter: {
		opacity: 1,
		transition: {
			type: "tween",
			duration: 0.4,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			type: "tween",
			duration: 0.4,
		},
	},
};

const Main = styled(motion.div)``;

const IndexPage = ({
	data: {
		homeYaml: { type, ...props },
	},
}) => {
	let Layout = sectionLayouts[type];
	let uiState = useUIState();
	useEffect(() => {
		uiState.setGrain(true);
		uiState.setLockNav(false);
	}, [uiState]);

	return (
		<Main initial="exit" animate="enter" exit="exit" variants={animations}>
			<Seo />
			<Layout {...props} components={components} />
		</Main>
	);
};

export const pageQuery = graphql`
	query Home {
		homeYaml {
			body {
				title
				content
				subtitle
				type
				attribution
				acts {
					active
					link
					linkTitle
					subtitle
					title
				}
			}
			title
			type
		}
	}
`;

export default IndexPage;
