import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "@emotion/styled";

import { spacing, ratio, getTheme } from "../../../styles/mixins";
import { design } from "../../../styles/settings";
import { useGetResizer } from "../../../contexts/Resizer";
import Ladder from "./Ladder";
import { useUIState } from "../../../contexts/UIStore";

const Container = styled.div`
	${spacing("paddingTop", "xl")};
	${spacing("paddingBottom", "l")};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	overflow: hidden;
	${ratio(
		"paddingTop",
		0,
		{
			mn: { base: 200, ratio: 1 },
			md: { base: 300, ratio: 1 },
			ms: { base: 411, ratio: 1 },
		},
		design
	)}
	${ratio(
		"paddingBottom",
		0,
		{
			mn: { base: 200, ratio: 1 },
			md: { base: 250, ratio: 1 },
			ms: { base: 352, ratio: 1 },
		},
		design
	)};
`;

export default function CoverLadders() {
	const area = useGetResizer();
	const ref = useRef();
	const [refTop, setRefTop] = useState(0);
	const [refBottom, setRefBottom] = useState(0);
	const [refPosition, setRefPosition] = useState(0);
	const [windowHeight, setWindowHeight] = useState(768);
	const uiState = useUIState();

	function setScrollThreshold(multiplier = 1) {
		return (refBottom - refTop) * multiplier + refTop;
	}

	useEffect(() => {
		const position = ref.current?.getBoundingClientRect();
		const top = position.top + window.pageYOffset;
		const bottom = top + position.height - window.innerHeight;
		setRefPosition(position);
		setRefTop(top);
		setRefBottom(bottom);
	}, [area]);

	const handleScroll = useCallback(() => {
		if (refPosition.top) {
			if (window.pageYOffset > refTop - windowHeight) {
				uiState.addTheme(getTheme("dark"), "home-dark");
			} else {
				uiState.removeTheme("home-dark");
				uiState.addTheme(getTheme("light"), "home-light");
			}
		}
	}, [refPosition, refTop, windowHeight, uiState]);

	useEffect(() => {
		handleScroll();
		window.removeEventListener("scroll", handleScroll, true);
		window.addEventListener("scroll", handleScroll, true);
		return function () {
			window.removeEventListener("scroll", handleScroll, true);
		};
	}, [refPosition, handleScroll]);

	useEffect(() => {
		let timeout = null;
		const handleResize = () => {
			updateWindowHeight();
		};

		const updateWindowHeight = () => {
			setWindowHeight(window.innerHeight);
		};

		updateWindowHeight();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
			clearTimeout(timeout);
		};
	}, []);

	return (
		<Container ref={ref}>
			<Ladder
				top="-9%"
				rotate={35}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(2.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.15 * windowHeight]}
				refPosition={refPosition}
				widths={[120, 170, 210]}
			/>
			<Ladder
				top="9%"
				right="4%"
				opacity={0.8}
				blur="0.2em"
				rotate={-70}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[0, 0.2 * windowHeight]}
				refPosition={refPosition}
				widths={[90, 110, 140]}
			/>
			<Ladder
				top="9%"
				left="12%"
				opacity={0.6}
				blur="0.15em"
				rotate={-60}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.05 * windowHeight]}
				refPosition={refPosition}
				widths={[66, 66, 66]}
			/>
			<Ladder
				top="37%"
				left="5%"
				opacity={0.6}
				blur="0.15em"
				rotate={-30}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.05 * windowHeight]}
				refPosition={refPosition}
				widths={[40, 50, 90]}
			/>
			<Ladder
				bottom="33%"
				left="14%"
				opacity={0.2}
				blur="0.15em"
				rotate={40}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.08 * windowHeight]}
				refPosition={refPosition}
				widths={[40, 50, 70]}
			/>
			<Ladder
				bottom="13%"
				right="45%"
				opacity={0.2}
				blur="0.15em"
				rotate={40}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.12 * windowHeight]}
				refPosition={refPosition}
				widths={[40, 50, 70]}
			/>
			<Ladder
				bottom="0"
				left="24%"
				opacity={0.6}
				blur="0.1em"
				rotate={-60}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(4),
				]}
				outputYRange={[-0.5 * windowHeight, 0.1 * windowHeight]}
				refPosition={refPosition}
				widths={[60, 80, 100]}
			/>
			<Ladder
				bottom="2%"
				right="25%"
				rotate={-60}
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.05 * windowHeight]}
				refPosition={refPosition}
				widths={[80, 60, 70]}
			/>
			<Ladder
				bottom="25%"
				right="30%"
				rotate={130}
				opacity={0.42}
				blur="0.1em"
				inputYRange={[
					setScrollThreshold(0) - setScrollThreshold(0.5),
					setScrollThreshold(5),
				]}
				outputYRange={[-0.5 * windowHeight, 0.05 * windowHeight]}
				refPosition={refPosition}
				widths={[80, 60, 70]}
			/>
			{/* <Ladder1 style={{y: ladder1ProgressY, rotate: 34}}>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder1>
			<Ladder2>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder2>
			<Ladder3>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder3>
			<Ladder4>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder4>
			<Ladder5>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder5>
			<Ladder6>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder6>
			<Ladder7>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder7>
			<Ladder8>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder8>
			<Ladder9>
				<motion.div>
					<img src={ladderImage} alt="" />
				</motion.div>
			</Ladder9> */}
		</Container>
	);
}
